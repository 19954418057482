const rules = {
  bankAccountNumber: (v: string): true | string => (/^[0-9]*$/.test(v) && v.length === 11) || "Kontonummeret må være 11 sifre",
  fullName: (value: string): true | string => value.split(" ").length >= 2 || "Både fornavn og etternavn.",
  email: (value: string): true | string => emailPattern.test(value) || "Ugyldig e-postadresse.",
  match: (value1: string, value2: string): true | string => value1 === value2 || "Passordene må være like.",
  min: (v: string): true | string => v.length >= 8 || "Minst 8 tegn.",
  min50: (v: string): true | string => v.length >= 50 || "Minst 50 tegn.",
  name: (v: string): true | string => v.length >= 2 || "Minst 2 tegn.",
  password: (v: string): true | string => v.length >= 8 || "Minst 8 tegn",
  personNumber: (v: string): true | string => /^[0-9]*$/.test(v) || "Ugyldig personnummer",
  phone: (v: string): true | string => /^[1-9][0-9]{7}$/.test(v) || "Ugyldig telefonnummer",
  postalCode: (v: string): true | string => /^[0-9]{4}$/.test(v) || "Ugyldig postnummer",
  required: (v: string): true | string => !!v || "Fyll ut dette, vær så snill 😇",
};

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default rules;
