



























import { capitalize } from "@/helpers/stringUtils";
import Learnlink from "@learnlink/interfaces";
import { computed, defineComponent, PropType, reactive } from "@vue/composition-api";
import { getReadableTeamTimeRange } from "@/helpers/timeUtils";
import { reorderTeam } from "@/api/customerService";
import { RoutesConfig } from "@/router/Routes";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { useRouter } from "@/router";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";

export default defineComponent({
  name: "AutomaticOnboardingForLoggedInUser",
  props: {
    course: {
      type: Object as PropType<Learnlink.Course.default>,
      required: true,
    },
    team: {
      type: Object as PropType<Learnlink.Team.Team>,
      required: true,
    },
    teamSessions: {
      type: Array as PropType<Learnlink.TeamSession.default[]>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      loading: false,
    });

    const { navigate } = useRouter();
    const notify = useNotifier();

    const weekdayString = computed(() =>
      props.teamSessions.length && capitalize(
          `${Moment.unix(props.teamSessions[0].startTime).format("dddd")}er kl. ${getReadableTeamTimeRange(props.teamSessions[0])}`,
      ),
    );

    async function submit() {
      vm.loading = true;
      try {
        await reorderTeam(userModule.state.userId, props.team.ID);
        notify({
          title: "Gratulerer! 🥳",
          message: "Dere har fått plass på kurs!",
          type: "success",
        });
        navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
      catch (e: any) {
        handleError(e);

        if (e.response?.data?.error?.message.includes("is not a parent")) {
          notify({
            title: "Du er ikke en forelder",
            message: "Kun foreldre kan melde seg inn i team.",
            type: "error",
          });
        }
        else {
          notify({
            title: "Noe gikk galt. 🤯",
            message: "Ta kontakt med support dersom problemet vedvarer.",
            type: "error",
          });
        }
      }
      vm.loading = false;
    }

    return {
      submit,
      weekdayString,
      vm,
    };
  },
});
