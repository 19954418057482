import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
const ENDPOINT = "/app/teams";

export async function getTeamsFromCourseId(ID: string): Promise<Learnlink.Team.Team[]> {
  const teamsRes = await learnlinkAPI.axios.get<Learnlink.Team.Team[]>(
    `/app/public/course/${ID}/teams`
  );

  return teamsRes.data;
}

export async function getTeamInOnboarding(ID: string): Promise<Learnlink.Team.Team> {
  const teamsRes = await learnlinkAPI.axios.get<Learnlink.Team.Team>(
    `/app/public/team/${ID}`
  );

  return teamsRes.data;
}

export async function addStudentToTeam(teamID: string, studentUID: string, uid: string, courseID: string): Promise<Learnlink.Team.Team> {
  const teamRes = await learnlinkAPI.axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/add-student/${uid}`, {
      uid,
      courseID,
      studentUID,
      teamID
    }
  );

  return teamRes.data;
}

export async function getMyActiveTeams(uid: string): Promise<Learnlink.Team.View.FullMetadata[]> {
  const teamsRes = await learnlinkAPI.axios.get<Learnlink.Team.View.FullMetadata[]>(
    `${ENDPOINT}/user/${uid}`
  );

  return teamsRes.data;
}

export async function getAllMyTeams(uid: string): Promise<Learnlink.Team.View.FullMetadata[]> {
  const teamsRes = await learnlinkAPI.axios.get<Learnlink.Team.View.FullMetadata[]>(
    `${ENDPOINT}/user-all/${uid}`
  );

  return teamsRes.data;
}

export async function getTeamCount(): Promise<number> {
  const res = await learnlinkAPI.axios.get<number>(`${ENDPOINT}/count/${userModule.state.userId}`);
  return res.data;
}

export async function postInfo(teamID: string, info: Learnlink.TeamInformation.Create): Promise<Learnlink.Team.Team> {
  const teamRes = await learnlinkAPI.axios.post<Learnlink.Team.Team>(
    `${ENDPOINT}/${teamID}/info`,
    info
  );

  return teamRes.data;
}

export async function postOverrideRoomURLforTeam(teamID: string, URL: string): Promise<Learnlink.Team.Team> {
  const teamRes = await learnlinkAPI.axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/${teamID}/overrideRoomURL`,
    { url: URL }
  );
  return teamRes.data;
}

export async function signContract(teamID: string, uid: string): Promise<Learnlink.Team.Team> {
  const teamRes = await learnlinkAPI.axios.post<Learnlink.Team.Team>(
    `${ENDPOINT}/${teamID}/sign/${uid}`
  );

  return teamRes.data;
}
