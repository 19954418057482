


























import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { getUser } from "@/api/userService";
import { RoutesConfig } from "@/router/Routes";
import { useModule } from "@/store/hooks";
import { useRouter } from "@/router";
import authModule from "@/store/modules/auth";
import SellerRegistration from "@/components/seller/SellerRegistration.vue";

export default defineComponent({
  name: "RegisterPL",
  components: {
    SellerRegistration,
  },
  setup() {
    const isLoading = ref(true);
    const { state } = useModule(authModule);
    const { navigate } = useRouter();

    onMounted(async () => {
      if (state.isAuthorized && state.uid) {
        const user = await getUser(state.uid);
        if (user.role === "seller") navigate(RoutesConfig.SELLER_DASHBOARD.path);
        else navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
      }
      isLoading.value = false;
    });

    return {
      isLoading,
    };
  },
});
