import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
const ENDPOINT = "/app/feedback";

export async function createFeedback(feedback: Learnlink.Feedback.Create): Promise<void> {
  await learnlinkAPI.axios.post<void>(
    `${ENDPOINT}/${feedback.uid}`, feedback,
  );
}

export async function getTeamFeedback(teamID: string): Promise<Learnlink.Feedback.View.App.Pro.FeedbackCard[]> {
  const feedbackRes = await learnlinkAPI.axios.get<Learnlink.Feedback.View.App.Pro.FeedbackCard[]>(
      `${ENDPOINT}/${teamID}`
  );
  return feedbackRes.data;
}

export async function getUnreadFeedback(teamID: string): Promise<number> {
  const feedbackRes = await learnlinkAPI.axios.get<string>(
      `${ENDPOINT}/unread/${teamID}`
  );
  return parseInt(feedbackRes.data);
}

export async function markRead(ID: string): Promise<Learnlink.Feedback.View.App.Pro.FeedbackCard> {
  const feedbackRes = await learnlinkAPI.axios.put<Learnlink.Feedback.View.App.Pro.FeedbackCard>(
      `${ENDPOINT}/read/${ID}`
  );
  return feedbackRes.data;
}
