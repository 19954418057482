






























import analytics from "@/services/analytics";
import handleError, { sanitizeError } from "@/helpers/errors";
import { RoutesConfig } from "@/router/Routes";
import { defineComponent, PropType, reactive } from "@vue/composition-api";
import { orderTeamByEmail } from "@/api/customerService";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";

export default defineComponent({
  name: "AutomaticOnboardingForExistingUser",
  props: {
    courseID: {
      type: String as PropType<string>,
      required: true,
    },
    email: {
      type: String as PropType<string>,
      required: true,
    },
    teamID: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      alreadyPartOfTeam: false,
      loading: false,
    });

    const notify = useNotifier();
    const { navigate } = useRouter();

    function toSigninPage(): void {
      navigate(RoutesConfig.LOGIN.path);
    }

    async function submit(): Promise<void> {
      vm.loading = true;
      try {
        await orderTeamByEmail(props.email.trim(), props.teamID);

        await analytics.track("onboardingComplete", {
          course: props.courseID,
        });
        notify({
          title: "Gratulerer med plass på kurs! 🥳",
          message: "",
          type: "success",
        });

        vm.alreadyPartOfTeam = true;

        emit("success");
      }
      catch (e) {
        handleError(e);
        if (sanitizeError(e).message.includes("is already part of team")) {
          vm.alreadyPartOfTeam = true;
          notify({
            title: "Dere er allerede påmeldt! 😊",
            message: "Logg inn for å se mer info.",
            type: "error",
          });
        }
        else {
          notify({
            title: "Noe gikk galt. 🤯",
            message: "Ta kontakt med support dersom problemet vedvarer.",
            type: "error",
          });
        }
      }
      vm.loading = false;
    }

    return {
      submit,
      toSigninPage,
      vm,
    };
  },
});
