

































































































import ProJobDescriptionDialog from "@/components/seller/ProJobDescriptionDialog.vue";
import TermsOfUseDialog from "@/components/shared/TermsOfUseDialog.vue";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "JobDescriptionsFooter",
  components: {
    TermsOfUseDialog,
    ProJobDescriptionDialog,
  },
  setup() {
    const vm = reactive({
      termsOfUseDialogVisible: false,
      proJobDescriptionDialogVisible: false,
    });

    function openJobDescription() {
      const win = window.open("https://www.learnlink.no/bli-personlig-laerer", "_blank");
      win?.focus();
    }

    return {
      openJobDescription,
      vm,
    };
  },
});
