























import TermsOfUseCustomer from "@/components/terms-of-use/TermsOfUseCustomer.vue";
import TermsOfUseSeller from "@/components/terms-of-use/TermsOfUseSeller.vue";
import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "TermsOfUseDialog",
  components: {
    TermsOfUseSeller,
    TermsOfUseCustomer,
  },
  props: {
    customerType: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    return {
      _value,
    };
  },
});
