import { getAnonymousId } from "@/services/analytics";
import { ROUTES } from "./routes";
import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
import publicIp from "public-ip";

const ENDPOINT = "/app/sellers";

export async function registerPL(seller: Learnlink.Seller.Create): Promise<string> {
  const usersRes = await learnlinkAPI.axios.post<string>(
    ROUTES.CREATE_PL,
    {
      ...seller,
      registrationIP: await publicIp.v4(),
      anonymousID: getAnonymousId(),
    });
  return usersRes.data;
}

export async function registerPro(seller: Learnlink.Seller.Create): Promise<string> {
  const usersRes = await learnlinkAPI.axios.post<string>(
    ROUTES.CREATE_PRO,
    {
      ...seller,
      registrationIP: await publicIp.v4(),
      anonymousID: getAnonymousId(),
    });
  return usersRes.data;
}

export async function sendRegisteredSellerConfirmation(uid: string): Promise<void> {
  await learnlinkAPI.axios.post<void>(
    `${ENDPOINT}/sendRegisteredSellerConfirmation/${uid}`
  );
}
