






































































































































































































import { auth } from "@/services/firebase";
import { computed, defineComponent, onMounted, PropType, reactive, ref } from "@vue/composition-api";
import { registerPL, registerPro } from "@/api/sellerService";
import { useNotifier } from "@/providers/notifier";
import axios from "axios";
import formRules from "@/helpers/formRules";
import handleError from "@/helpers/errors";
import TermsOfUse from "../terms-of-use/TermsOfUse.vue";
import { getOnboardingSettings } from "@/api/platformSettingsService";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "SellerRegistration",
  components: {
    TermsOfUse,
  },
  props: {
    pro: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      consent: false,
      loading: false,
      onboardingSettings: {
        PLOnboardingMessage: "",
        PLOnboardingOpen: true,
        proOnboardingMessage: "",
        proOnboardingOpen: true,
      } as Learnlink.PlatformSettings.Onboarding,
      repeatPassword: "",
      seller: {
        birthdate: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
      },
    });
    const validForm = ref(false);
    const notify = useNotifier();

    onMounted(async () => {
      vm.loading = true;
      vm.onboardingSettings = await getOnboardingSettings();
      vm.loading = false;
    });

    const passwordCheck = computed(() => {
      return vm.seller.password === vm.repeatPassword
        ? {
            color: "success",
            icon: "mdi-check-circle",
          }
        : {
            color: "error",
            icon: "mdi-close-circle",
          };
    });

    const register = async () => {
      vm.loading = true;
      try {
        if (validForm.value) {
          if (props.pro) {
            await registerPro(vm.seller);
          }
          else {
            await registerPL(vm.seller);
          }
          notify({
            title: "Suksess! 🥳",
            message: "Kontoen din er opprettet. Du blir nå logget inn så du kan fullføre søknaden.",
            type: "success",
          });
          await auth.signInWithEmailAndPassword(vm.seller.email, vm.seller.password);
        }
        else {
          notify({
            title: "Noe mangler! 🤔",
            message: "Fyll ut alle feltene, og prøv igjen.",
            type: "error",
          });
        }
      }
      catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 422) {
            notify({
              title: "Søknaden er mangelfull 🤯",
              message: "Vennligst fyll ut hele skjemaet. Kontakt support om noe skulle være uklart.",
              type: "error",
            });
          }
          else if (e.response?.status === 409) {
            notify({
              title: "E-postadressen er allerede i bruk 🤯",
              message: "Du kan logge inn ved å trykke på knappen oppe til høyre.",
              type: "error",
            });
          }
        }
        else {
          notify({
            title: "Klarte ikke sende søknaden 🤯",
            message: "Kontakt support hvis problemet vedvarer.",
            type: "error",
          });
        }
        handleError(e);
        window.Intercom && window.Intercom("showNewMessage", "Hei! Jeg opplever problemer med å registrere meg! Kontakt meg på e-post eller telefon: ");
      }
      vm.loading = false;
    };

    return {
      formRules,
      passwordCheck,
      register,
      validForm,
      vm,
    };
  },

});

