import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/lessons";

export const createLesson = async(lesson: Learnlink.Lesson.Create, repeat: number): Promise<Learnlink.Lesson.FullViewApp[]> => {
  const lessonRes = await learnlinkAPI.axios.post<Learnlink.Lesson.FullViewApp[]>(
    `${ENDPOINT}/${repeat}`, lesson
  );
  return lessonRes.data;
};

export const getFirstLesson = async(projectID: string): Promise<Learnlink.Lesson.FullViewApp> => {
  const res = await learnlinkAPI.axios.get<Learnlink.Lesson.FullViewApp>(`${ENDPOINT}/firstLesson/${projectID}`);
  return res.data;
};

export const getLessons = async(userType: Learnlink.Type.UserType, userId: string): Promise<Learnlink.Lesson.FullViewApp[]> => {
  const lessonRes = await learnlinkAPI.axios.get<Learnlink.Lesson.FullViewApp[]>(
    `${ENDPOINT}/${userType}/${userId}`
  );
  return lessonRes.data;
};

export const getLesson = async(lessonID: string): Promise<Learnlink.Lesson.FullViewApp> => {
  const lessonRes = await learnlinkAPI.axios.get<Learnlink.Lesson.FullViewApp>(
    `lessons/${lessonID}`
  );
  return lessonRes.data;
};

export const getLessonsWithoutReport = async(sellerUID: string): Promise<Learnlink.Lesson.FullViewApp[]> => {
  const res = await learnlinkAPI.axios.get<Learnlink.Lesson.FullViewApp[]>(`${ENDPOINT}/pendingReports/${sellerUID}`);
  return res.data;
};

export const getIncompleteReportsCount = async(): Promise<number> => {
  const res = await learnlinkAPI.axios.get<number>(`${ENDPOINT}/incompleteReportsCount/${userModule.state.userId}`);
  return res.data;
};

export const cancelLesson = async(lessonID: string): Promise<Learnlink.Lesson.FullViewApp> => {
  const lessonRes = await learnlinkAPI.axios.put<Learnlink.Lesson.FullViewApp>(
    `${ENDPOINT}/cancel/${lessonID}`
  );
  return lessonRes.data;
};

export const updateLesson = async(lesson: Learnlink.Lesson.Update): Promise<Learnlink.Lesson.FullViewApp> => {
  const lessonRes = await learnlinkAPI.axios.put<Learnlink.Lesson.FullViewApp>(
    `${ENDPOINT}/${lesson.ID}`,
    lesson
  );
  return lessonRes.data;
};

export const rateLesson = async(lessonID: string, rating: number): Promise<void> => {
  const res = await learnlinkAPI.axios.put(`/lessons/rate/${lessonID}`, {
    rating: rating
  });
  return res.data;
};
