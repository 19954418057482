import { getAnonymousId } from "@/services/analytics";
import { userModule } from "@/store/modules/user";
import { ROUTES } from "./routes";
import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
import publicIp from "public-ip";

const ENDPOINT = "/app/customers";

export async function createContactRequest(customer: Learnlink.Customer.Create & Partial<Learnlink.Project.Create>): Promise<string> {
  const accountsRes = await learnlinkAPI.axios.post<{ uid: string }>(
    ROUTES.CONTACT_ME,
    customer,
  );
  return accountsRes.data.uid;
}

export async function createCustomerAutomaticOnboarding(customer: Learnlink.Customer.Create, teamID: string): Promise<Learnlink.Team.Team> {
  const usersRes = await learnlinkAPI.axios.post<Learnlink.Team.Team>(
    `${ENDPOINT}/order-team/${teamID}`,
    {
      ...customer,
      registrationIP: publicIp.v4(),
      anonymousID: getAnonymousId(),
    });
  return usersRes.data;
}

export async function createStudent(student: Learnlink.Student.Create): Promise<string> {
  const studentRes = await learnlinkAPI.axios.post<{ uid: string }>(
    ROUTES.CREATE_STUDENT,
    student,
  );
  return studentRes.data.uid;
}

export async function updateStudent(student: Learnlink.Student.Update): Promise<Learnlink.Student.Student> {
  const studentRes = await learnlinkAPI.axios.put<Learnlink.Student.Student>(
    `${ENDPOINT}/student/${student.uid}`,
    student,
  );
  return studentRes.data;
}

export async function getStudent(studentUID: string): Promise<Learnlink.Student.Student> {
  const studentRes = await learnlinkAPI.axios.get<Learnlink.Student.Student>(
    `${ENDPOINT}/student/${studentUID}`,
  );
  return studentRes.data;
}

export async function getStudents(parentUID: string): Promise<Learnlink.Student.View.App.Thumbnail[]> {
  const studentRes = await learnlinkAPI.axios.get<Learnlink.Student.View.App.Thumbnail[]>(
    `${ENDPOINT}/students/${parentUID}`,
  );
  return studentRes.data;
}

export async function reorderTeam(uid: string, teamID: string): Promise<void> {
  await learnlinkAPI.axios.post<void>(
    `${ENDPOINT}/reorder-team/${uid}/${teamID}`,
  );
}

export async function orderTeamByEmail(email: string, teamID: string): Promise<void> {
  await learnlinkAPI.axios.post<void>(
    `${ENDPOINT}/order-team-by-email/${email}/${teamID}`,
  );
}

export async function getUnreadPracticalInfoCount(): Promise<number> {
  const unreadPracticalInfoCount = await learnlinkAPI.axios.get(
    `${ENDPOINT}/unread-practical-info-count/${userModule.state.userId}`,
  );
  return +unreadPracticalInfoCount.data;
}

export async function updateLastReadPracticalInfo(): Promise<void> {
  await learnlinkAPI.axios.put(
    `${ENDPOINT}/last-read-practical-info/${userModule.state.userId}`
  );
}
