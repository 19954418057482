import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export function formatUnixTimestamp(unixTimestamp: number, format: string): string {
  return Moment.unix(unixTimestamp).format(format);
}

export function getBirthYearString(birthYears: number[]): string {
  const birthYearsString = birthYears.toString().replaceAll(",", birthYears.length > 2 ? ", " : " og ");
  return `Barn født i ${birthYearsString}`;
}

export function getReadableTeamSessionStartTime(unixTimeStamp: number): string {
  const startTime = Moment.unix(unixTimeStamp);
  return `${startTime.format("DD. MMMM, YYYY")}`;
}

export function getReadableLastTeamSessionDate(firstSessionStartTime: number, course: Learnlink.Course.default): string {
  const startTime = Moment.unix(firstSessionStartTime);
  const endTime = Moment.unix(startTime.unix()).add(course.sessions - 1, course.weeks > 1 ? "weeks" : "days");
  return `${startTime.format("DD. MMMM")} til ${endTime.format("DD. MMMM")}`;
}

export function getReadableTeamTimeRange(session: Learnlink.TeamSession.default): string {
  const startTime = Moment.unix(session.startTime);
  const endTime = Moment.unix(session.endTime);
  return `${startTime.format("HH:mm")} – ${endTime.format("HH:mm")}`;
}

export function isValidBirthdate(birthdate: string): boolean {
  if (!birthdate) return false;

  const propDay = birthdate.split("-")[2];
  const propMonth = birthdate.split("-")[1];
  const propYear = birthdate.split("-")[0];

  return !(!birthdate || !propDay || !propMonth || !propYear);
}

export function stringTimeToUnix(date: string, time: string): number {
  const _date = Moment(date).format("YYYY-MM-DD");
  return Moment(`${_date} ${time}`, "YYYY-MM-DD HH:mm").unix();
}

export function getWeekNumberFromTimestamp(timestamp: number): number {
  return Moment.unix(timestamp).weeks();
}

export function timestampRangeToHours(startTime: number, endTime: number): string {
  return String(Math.round((endTime - startTime) / 3600 * 100) / 100).replace(".", ",");
}

export type Weekday =
  "monday" |
  "tuesday" |
  "wednesday" |
  "thursday" |
  "friday" |
  "saturday" |
  "sunday";

export const weekdays = [
  {
    value: "monday",
    text: "Mandager",
  },
  {
    value: "tuesday",
    text: "Tirsdager",
  },
  {
    value: "wednesday",
    text: "Onsdager",
  },
  {
    value: "thursday",
    text: "Torsdager",
  },
  {
    value: "friday",
    text: "Fredager",
  },
  {
    value: "saturday",
    text: "Lørdager",
  },
  {
    value: "sunday",
    text: "Søndager",
  },
];
