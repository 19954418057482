import "./setup";
import { gucciRouter } from "./router";
import App from "./App.vue";
import handleError from "@/helpers/errors";
import store from "./store";
import Vue from "vue";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.config.errorHandler = (err: Error) => {
  handleError(err);
};

const VueApp = new Vue({
  router: gucciRouter,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

declare global {
  interface Window {
    Cypress: unknown;
    app: {
        Vue: Vue;
        store: typeof store;
    }
  }
}

if (window.Cypress) {
  window.app = {
    Vue: VueApp,
    store,
  };
}
