import learnlinkAPI from "@/api/learnlinkAPI";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/app/teamSessions";

export async function getTeamSessionsForTeam(teamID: string): Promise<Learnlink.TeamSession.default[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.TeamSession.default[]>(
    `/app/public/team-sessions/${teamID}`
  );

  return res.data;
}

export async function getTeamSessionStartTimesForOnboardingByCourse(courseID: string): Promise<Learnlink.App.Onboarding.TeamCourseSession[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.App.Onboarding.TeamCourseSession[]>(
    `/app/public/course/${courseID}/startTimes`
  );

  return res.data;
}

export async function getAllTeamSessionsForUser(uid: string): Promise<Learnlink.TeamSession.default[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.TeamSession.default[]>(
    `${ENDPOINT}/user/${uid}`
  );

  return res.data;
}

export async function getAllTeamSessionsWithoutPerformanceForUser(uid: string): Promise<Learnlink.TeamSession.PerformanceAppView[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.TeamSession.PerformanceAppView[]>(
    `${ENDPOINT}/user/withoutPerformance/${uid}`
  );

  return res.data;
}

export async function createTeamSessionReport(report: Learnlink.TeamSession.CreateReport): Promise<void> {
  const res = await learnlinkAPI.axios.post<void>(
    `${ENDPOINT}/report`,
    report,
  );

  return res.data;
}
