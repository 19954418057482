<template>
  <div
    style="width: 100%; margin: auto"
    class="text-center"
  >
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner"
};
</script>
