





























































































































































































































































































































export default {
  name: "TermsOfUseSeller",
};
