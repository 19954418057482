import Learnlink from "@learnlink/interfaces";

export function getStudentID(customerUID: string): string {
  return customerUID.substr(0, 3).toUpperCase();
}

export function getOnlineRoom(projectID: string): number {
  let val = 0;
  if (projectID.length === 0) {
    return val;
  }
  for (let i = 0; i < projectID.length; i++) {
    const char = projectID.charCodeAt(i);
    val = ((val << 5) - val) + char;
    val = val & val;
  }

  if (val < 0) {
    val = val * val;
  }

  return val;
}

export const TargetGroup: {[key: string]: Learnlink.Project.TargetGroup} = {
  "barneskole": "elementary",
  "ungdomskole": "middle",
  "vgs": "high",
  "kodekurs": "code",
};

export const ValidTargetGroups = ["elementary", "middle", "high", "code"]; // ["barneskole", "ungdomskole", "vgs", "kodekurs"];

export const TargetGroups: Learnlink.PickerItems.default[] = [
  {
    ID: "elementary",
    icon: "mdi-bus-school",
    name: "Barneskole",
  },
  {
    ID: "middle",
    icon: "mdi-book-open-variant",
    name: "Ungdomskole",
  },
  {
    ID: "high",
    icon: "mdi-school",
    name: "Videregående",
  },
  {
    ID: "code",
    icon: "mdi-laptop",
    name: "Koding 1–1",
  },
];
