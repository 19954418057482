




























































































import { Notification, provideNotifier } from "./providers/notifier";
import AppBar from "@/components/app/layout/AppBar.vue";
import AuthorizedRoot from "./components/app/AuthorizedRoot.vue";
import AutomaticTeamOnboarding from "@/views/onboarding/AutomaticTeamOnboarding.vue";
import AvailableCoursePickerFullPage from "@/views/onboarding/AvailableCoursePickerFullPage.vue";
import ContactMe from "@/views/onboarding/ContactMe.vue";
import Login from "@/views/Login.vue";
import Moment from "@/services/moment";
import ParentFeedback from "@/views/customer/ParentFeedback.vue";
import RegisterPL from "./views/onboarding/sellers/RegisterPL.vue";
import RegisterPro from "@/views/onboarding/sellers/RegisterPro.vue";
import SellerFeedback from "@/views/seller/SellerFeedback.vue";
import SellerOnboarding from "./components/seller/SellerOnboarding.vue";
import StudentFeedback from "@/views/student/StudentFeedback.vue";
import authModule from "./store/modules/auth";
import handleError from "./helpers/errors";
import store from "./store";
import { RoutesConfig } from "./router/Routes";
import { auth as firebaseAuth } from "./services/firebase";
import { computed, defineComponent, provide, reactive, ref } from "@vue/composition-api";
import { configureScope } from "@sentry/vue";
import { gucciRouter, provideRouter, useRouter } from "./router";
import { identifyUser } from "./services/analytics";
import { loadDynamicModules } from "./store/dynamicModules";
import { login } from "./api/authService";
import { uiModule } from "@/store/modules/ui";
import { useModule } from "./store/hooks";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "App",
  components: {
    AppBar,
    AuthorizedRoot,
    AutomaticTeamOnboarding,
    AvailableCoursePickerFullPage,
    ContactMe,
    Login,
    ParentFeedback,
    RegisterPL,
    RegisterPro,
    SellerFeedback,
    SellerOnboarding,
    StudentFeedback,
  },
  setup() {
    const isLoading = ref(true);
    const { state, mutations } = useModule(authModule);
    provideRouter(gucciRouter);
    provideNotifier((notification) => notify(notification));
    const { currentRoute, navigate, refresh } = useRouter();

    const vm = reactive({
      notification: {
        title: "",
        message: "",
        type: "success",
      } as Notification,
      alertVisible: false,
    });

    function notify(notification: Notification) {
      vm.notification = notification;
      vm.alertVisible = true;
    }

    firebaseAuth.onAuthStateChanged(async (user) => {
      if (user) {
        identifyUser(user.uid);
        configureScope((scope) => {
          scope.setUser({
            id: user.uid,
            email: user.email ?? undefined,
          });
        });

        try {
          await loadDynamicModules(store, user.uid);
          mutations.authorize(user.uid);
          isLoading.value = false;
          if (window.location.hostname !== "localhost") {
            window.ORIBI && window.ORIBI.api("setUserEmail", user.email);
          }
          await uiModule.actions.fetchProjectCount();
          if (userModule.state.user.role === "seller") {
            uiModule.actions.fetchIncompleteReportsCount();
            uiModule.actions.fetchUnreadNewsMessageCount();
          }
          else {
            uiModule.actions.fetchTeamCount();
            uiModule.actions.fetchUnreadPracticalInfoCount();
            if (userModule.state.user.role === "parent") uiModule.actions.fetchUnpaidInvoices();
          }

          if (!state.simulate) {
            await login(user.uid);
          }
        }
        catch (e) {
          handleError(e);
          notify({
            title: "Kunne ikke logge inn",
            message: "Brukeren er ugyldig. Prøv å logge inn med en annen bruker.",
            type: "error",
          });
          mutations.deAuthorize();
          firebaseAuth.signOut();
          navigate(RoutesConfig.LOGIN.path);

          configureScope((scope) => {
            scope.setUser(null);
          });
        }
      }
      else {
        mutations.deAuthorize();
        isLoading.value = false;
      }
    });

    const proOnboardingActive = computed(() => {
      if (userModule.state.user.role === "seller" &&
        userModule.state.user.registerDate > Moment("2021-07-01").unix()) {
        return !userModule.state.profile.applicationApproved;
      }
      return false;
    });

    const onboardingActive = computed(() => currentRoute.value.name === RoutesConfig.ORDER_TEAM.name ||
      currentRoute.value.name === RoutesConfig.COURSE_PICKER_FULL_PAGE.name,
    );

    function loginLoaderActive(val: boolean) {
      isLoading.value = val;
    }

    provide("loginLoaderActive", loginLoaderActive);
    provide("onboardingActive", onboardingActive);
    provide("proOnboardingActive", proOnboardingActive);

    const simulationInfo = computed(() => {
      return state.simulate ? `${userModule?.state.profile.firstName} ${userModule?.state.profile.lastName} ${userModule?.state.userId}` : "";
    });

    function stopSimulation() {
      navigate(RoutesConfig.SELLER_DASHBOARD.path);
      refresh();
    }

    return {
      currentRoute,
      isAuthorized: computed(() => state.isAuthorized),
      isLoading,
      onboardingActive,
      proOnboardingActive,
      RoutesConfig,
      simulationInfo,
      state,
      stopSimulation,
      vm,
    };
  },
});
