


































import { defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ButtonPicker",
  props: {
    items: {
      type: Array as PropType<Learnlink.PickerItems.default[]>,
      required: true,
    },
    value: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      emit,
    };
  },
});
