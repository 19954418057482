export default {
  appVersion: process.env.GIT_COMMIT,
  apiURL: process.env.VUE_APP_API,
  appURL: process.env.VUE_APP_URL,
  buildTarget: process.env.BUILD_TARGET,
  legacyMode: false,
  sentry: {
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  },
  firebase: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  },
  segment: {
    apiKey: process.env.VUE_APP_SEGMENT_KEY,
  },
  stripeKey: process.env.VUE_APP_STRIPE_KEY,
};
