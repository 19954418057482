
















































import { auth } from "@/services/firebase";
import { defineComponent, reactive } from "@vue/composition-api";
import { useNotifier } from "@/providers/notifier";
import { getAuthErrorNotification } from "@/helpers/errors";

export default defineComponent({
  name: "LoginForm",
  setup() {
    const notify = useNotifier();

    const vm = reactive({
      email: "",
      loading: false,
      password: "",
    });

    async function signInUsingEmail() {
      vm.loading = true;
      let sanitizedEmail = vm.email.replace(/\s/g, "");

      if (sanitizedEmail.length === 8 && !sanitizedEmail.includes("@")) {
        sanitizedEmail = `${sanitizedEmail}@learnlink.no`;
      }

      try {
        await auth.signInWithEmailAndPassword(sanitizedEmail, vm.password);
      }
      catch (e) {
        notify(getAuthErrorNotification(e));
      }

      vm.loading = false;
    }

    return {
      signInUsingEmail,
      vm,
    };
  },
});
