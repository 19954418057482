import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import no from "vuetify/src/locale/no";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { no },
    current: "no",
  },
  theme: {
    themes: {
      light: {
        primary: "#4200a6",
        secondary: "#A569FF",
        background: "#FFFFFF",
      },
      dark: {
        primary: "#A239A0",
        secondary: "#C054BE",
        warning: "#CE6348",
        error: "#D63636",
        success: "#2BBD29",
        info: "#eeeeee",
        background: "#2C2C2C",
      },
    },
  },
});
