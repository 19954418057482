
























import ProJobDescription from "@/components/seller/ProJobDescription.vue";
import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "ProJobDescriptionDialog",
  components: { ProJobDescription },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    return {
      _value,
    };
  },
});
