






















































































































import { createFeedback } from "@/api/feedbackService";
import { defineComponent, reactive } from "@vue/composition-api";
import {
  negativeReasons,
  negativeReasonsLocale,
  negativeReasonTitle,
  ratingSymbols,
  ratingText,
} from "@/helpers/feedbackUtils";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "SellerFeedback",
  setup() {
    const vm = reactive({
      loading: false,
      message: "",
      rating: 0,
      sellerNegativeReason: "" as Learnlink.Feedback.negativeReason,
      submitted: false,
      step: 1,
    });
    const notify = useNotifier();
    const { currentRoute } = useRouter();
    const { teamid, uid } = currentRoute.value.params;

    function rate(rating: number) {
      vm.rating = rating;
      if (rating > 3) vm.step += 2;
      else vm.step++;
    }

    function setNegativeReason(reason: Learnlink.Feedback.negativeReason) {
      vm.sellerNegativeReason = reason;
      vm.step++;
    }

    async function submit() {
      vm.loading = true;
      try {
        await createFeedback({
          uid: uid,
          message: vm.message,
          rating: vm.rating,
          sellerNegativeReason: vm.sellerNegativeReason,
          teamID: teamid,
          userRole: "seller",
        });
        vm.submitted = true;
      }
      catch (e) {
        notify({
          title: "Noe gikk galt! 🤯",
          message: "Vennligst prøv igjen, eller kontakt oss på support dersom problemet vedvarer.",
          type: "error",
        });
        handleError(e);
      }
      vm.loading = false;
    }

    return {
      negativeReasons,
      negativeReasonsLocale,
      negativeReasonTitle,
      rate,
      ratingSymbols,
      ratingText,
      setNegativeReason,
      submit,
      vm,
    };
  },
});
