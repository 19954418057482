

























































































import { createFeedback } from "@/api/feedbackService";
import { ratingSymbols, ratingText } from "@/helpers/feedbackUtils";
import { defineComponent, reactive } from "@vue/composition-api";
import { RoutesConfig } from "@/router/Routes";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import handleError from "@/helpers/errors";
import rules from "@/helpers/formRules";

export default defineComponent({
  name: "ParentFeedback",
  setup() {
    const vm = reactive({
      loading: false,
      message: "",
      rating: 0,
      submitted: false,
      validForm: false,
    });
    const notify = useNotifier();
    const { currentRoute } = useRouter();
    const { teamid, uid } = currentRoute.value.params;

    async function submit() {
      if (vm.validForm) {
        vm.loading = true;
        try {
          await createFeedback({
            uid: uid,
            message: vm.message,
            rating: vm.rating,
            teamID: teamid,
            userRole: "parent",
          });
          vm.submitted = true;
        }
        catch (e) {
          notify({
            title: "Noe gikk galt! 🤯",
            message: "Vennligst prøv igjen, eller kontakt oss på support dersom problemet vedvarer.",
            type: "error",
          });
          handleError(e);
        }
        vm.loading = false;
      }
      else {
        notify({
          title: "Noe mangler! 🤯",
          message: "Er du sikker på at du har fylt ut tilbakemeldingen og gitt vurdering?",
          type: "error",
        });
      }
    }
    return {
      ratingSymbols,
      ratingText,
      RoutesConfig,
      rules,
      submit,
      vm,
    };
  },
});
