import { RoutesConfig } from "@/router/Routes";
import { uiModule } from "@/store/modules/ui";
import { userModule } from "@/store/modules/user";

type MenuItem = {
  title: string;
  route: string;
  icon: string;
  actionText?: string;
  counter?: number;
};
type MenuItems = MenuItem[];

export const getMenuItems = (showSellerRoutes: boolean, showStudentRoutes: boolean): MenuItems => {
  const sellerRoutes: MenuItems = [
    {
      title: RoutesConfig.SELLER_DASHBOARD.name,
      route: RoutesConfig.SELLER_DASHBOARD.path,
      icon: "mdi-view-dashboard",
    },
    {
      title: RoutesConfig.SELLER_NEWS.name,
      route: RoutesConfig.SELLER_NEWS.path,
      icon: "mdi-newspaper-variant",
      counter: uiModule.state.unreadNewsMessageCount,
    },
    {
      title: RoutesConfig.SELLER_LESSONS.name,
      route: RoutesConfig.SELLER_LESSONS.path,
      icon: "mdi-calendar-month",
    },
    {
      title: RoutesConfig.SELLER_REPORTS.name,
      route: RoutesConfig.SELLER_REPORTS.path,
      icon: "mdi-trending-up",
      counter: uiModule.state.incompleteReportsCount,
    },
    {
      title: RoutesConfig.SELLER_PAYOUTS.name,
      route: RoutesConfig.SELLER_PAYOUTS.path,
      icon: "mdi-currency-usd",
    },
    {
      title: RoutesConfig.SELLER_PROFILE.name,
      route: RoutesConfig.SELLER_PROFILE.getPath(userModule.state.userId),
      icon: "mdi-account",
      actionText: `${userModule.getters.getFullName()}`,
    },
    {
      title: RoutesConfig.SELLER_RESOURCES.name,
      route: RoutesConfig.SELLER_RESOURCES.path,
      icon: "mdi-space-invaders",
    },
    {
      title: RoutesConfig.VIDEOS.name,
      route: RoutesConfig.VIDEOS.path,
      icon: "mdi-movie-play",
    },
    {
      title: "Innstillinger",
      route: RoutesConfig.SELLER_SETTINGS.path,
      icon: "mdi-cog",
    },
  ];

  const customerRoutes: MenuItems = [
    {
      title: RoutesConfig.CUSTOMER_DASHBOARD.name,
      route: RoutesConfig.CUSTOMER_DASHBOARD.path,
      icon: "mdi-view-dashboard",
    },
    {
      title: RoutesConfig.CUSTOMER_LESSONS.name,
      route: RoutesConfig.CUSTOMER_LESSONS.path,
      icon: "mdi-calendar-month",
    },
    {
      title: RoutesConfig.VIDEOS.name,
      route: RoutesConfig.VIDEOS.path,
      icon: "mdi-movie-play",
    },
    {
      title: RoutesConfig.CUSTOMER_PAYMENTS.name,
      route: RoutesConfig.CUSTOMER_PAYMENTS.getPath(),
      icon: "mdi-credit-card-outline",
      counter: uiModule.state.unpaidInvoices,
    },
    {
      title: "Innstillinger",
      route: RoutesConfig.CUSTOMER_SETTINGS.path,
      icon: "mdi-cog",
    },
  ];

  const studentRoutes: MenuItems = [
    {
      title: RoutesConfig.STUDENT_DASHBOARD.name,
      route: RoutesConfig.STUDENT_DASHBOARD.path,
      icon: "mdi-view-dashboard",
    },
    {
      title: RoutesConfig.STUDENT_CALENDAR.name,
      route: RoutesConfig.STUDENT_CALENDAR.path,
      icon: "mdi-calendar-month",
    },
    {
      title: RoutesConfig.STUDENT_DIPLOMAS.name,
      route: RoutesConfig.STUDENT_DIPLOMAS.path,
      icon: "mdi-certificate-outline",
    },
    {
      title: RoutesConfig.VIDEOS.name,
      route: RoutesConfig.VIDEOS.path,
      icon: "mdi-movie-play",
    },
    {
      title: "Innstillinger",
      route: RoutesConfig.STUDENT_SETTINGS.path,
      icon: "mdi-cog",
    },
  ];

  // if (uiModule.state.teamCount) {
  //   customerRoutes.push({
  //     title: RoutesConfig.CUSTOMER_STUDENTS.name,
  //     route: RoutesConfig.CUSTOMER_STUDENTS.path,
  //     icon: "mdi-account-supervisor",
  //   });
  // }

  if (uiModule.state.projectCount) {
    customerRoutes.splice(1, 0, {
      title: RoutesConfig.CUSTOMER_PRACTICAL_INFORMATION.name,
      route: RoutesConfig.CUSTOMER_PRACTICAL_INFORMATION.path,
      icon: "mdi-book-open-variant",
      counter: uiModule.state.unreadPracticalInfoCount,
    });
    customerRoutes.splice(3, 0, {
      title: RoutesConfig.CUSTOMER_REPORTS.name,
      route: RoutesConfig.CUSTOMER_REPORTS.path,
      icon: "mdi-trending-up",
    });
  }

  if (uiModule.state.teamCount) {
    customerRoutes.splice(1, 0, {
      title: RoutesConfig.CUSTOMER_DIPLOMAS.name,
      route: RoutesConfig.CUSTOMER_DIPLOMAS.path,
      icon: "mdi-certificate-outline",
    });
  }

  return showSellerRoutes
    ? sellerRoutes
    : showStudentRoutes
      ? studentRoutes
      : customerRoutes;
};
