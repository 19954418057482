


















































import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import TermsOfUseCustomer from "./TermsOfUseCustomer.vue";
import TermsOfUseSeller from "./TermsOfUseSeller.vue";

export default defineComponent({
  components: { TermsOfUseSeller, TermsOfUseCustomer },
  name: "TermsOfUse",
  props: {
    customerType: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value.value);
    });

    const termsVisible = ref(false);

    return {
      _value,
      termsVisible,
    };
  },
});
