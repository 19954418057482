





























































































































import ReasonsToRegister from "@/components/customer/ReasonsToRegister.vue";
import { TargetGroup, TargetGroups, ValidTargetGroups } from "@/helpers/projectUtils";
import { auth } from "@/services/firebase";
import { createContactRequest } from "@/api/customerService";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { RoutesConfig } from "@/router/Routes";
import { weekdays, Weekday } from "@/helpers/timeUtils";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import ButtonPicker from "@/components/onboarding/ButtonPicker.vue";
import ContactInformationForm from "@/components/onboarding/ContactInformationForm.vue";
import environment from "@/config/environment";
import handleError, { sanitizeError } from "@/helpers/errors";
import PrevNextButtons from "@/components/onboarding/PrevNextButtons.vue";
import TermsOfUse from "@/components/terms-of-use/TermsOfUse.vue";

import analytics, { getAnonymousId } from "@/services/analytics";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ContactMe",
  components: {
    ReasonsToRegister,
    PrevNextButtons,
    ButtonPicker,
    ContactInformationForm,
    TermsOfUse,
  },
  setup() {
    const notify = useNotifier();
    const { navigate, currentRoute } = useRouter();
    const vm = reactive({
      consent: false,
      contactInformation: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        studentName: "",
        subscribeToNewsletter: false,
        valid: false,
      } as Learnlink.Customer.ContactInformation,
      customerText: "",
      loading: false,
      step: 1,
      targetGroup: "" as Learnlink.Project.TargetGroup,
      weekdays: [] as Weekday[],
    });

    onMounted(() => {
      analytics.track(`contactMeStep${vm.step}`);

      const targetGroup = currentRoute.value.params.targetGroup;
      if (ValidTargetGroups.includes(TargetGroup[targetGroup])) {
        vm.targetGroup = TargetGroup[targetGroup];
        next();
      }
    });

    function setTargetGroup(value: Learnlink.Project.TargetGroup) {
      vm.targetGroup = value;
      next();
    }

    function next(): void {
      vm.step++;

      // WARNING: Possible issues when sending a nested object.
      analytics.track(`contactMeStep${vm.step}`, vm);
    }

    let anonymousID: string;
    try {
      anonymousID = getAnonymousId();
    }
    catch (e) {
      handleError(e);
    }

    async function submit() {
      vm.loading = true;
      const info = vm.contactInformation;
      const { valid, ...contactInformation } = info;
      if (valid) {
        try {
          await createContactRequest({
            ...contactInformation,
            anonymousID,
            channel: "contactMe",
            targetGroup: vm.targetGroup,
            description: vm.customerText,
            weekdays: vm.weekdays,
          });
          vm.step++;
          await analytics.track("onboardingComplete");

          await auth.signInWithEmailAndPassword(vm.contactInformation.email, vm.contactInformation.password);
          navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
        }
        catch (e) {
          handleError(e);
          const sanitizedError = sanitizeError(e);

          if (sanitizedError.code === 409 || sanitizedError.message.includes("The email address is already in use by another account")) {
            notify({
              title: "E-posten er allerede i bruk! 🤯",
              message: "Det ser ut til at du allerede har en bruker. Logg inn på app.learnlink.no, og kontakt oss i chatten for nye bestillinger. 😄",
              type: "error",
            });
          }
          else {
            notify({
              title: "Registrering feilet! 🤯",
              message: "Noe gikk galt under registreringen av informasjonen. Vennligst kontakt support dersom problemet vedvarer.",
              type: "error",
            });
          }
        }
      }
      else {
        notify({
          title: "Noe mangler! 🤯",
          message: "Skjemaet er mangelfullt. Vennligst fyll inn det som mangler.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    function toLogin(): void {
      if (environment.firebase.projectId === "learnlink-prod") {
        window.location.href = "https://app.learnlink.no";
      }
      else if (environment.firebase.projectId === "learnlink-stag") {
        window.location.href = "https://app.staging.learnlink.no";
      }
    }

    function toReadMore(): void {
      window.location.href = "https://www.learnlink.no/var-metode";
    }

    return {
      next,
      weekdays,
      submit,
      setTargetGroup,
      TargetGroups,
      toLogin,
      toReadMore,
      vm,
    };
  },
});
