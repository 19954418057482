export enum ROUTES {
  AUTOMATIC_EMAIL_ONBOARDING = "/app/customers/order-team-by-email/*/*",
  AUTOMATIC_ONBOARDING = "/app/customers/order-team/*",
  AUTOMATIC_ONBOARDING_REORDER = "/app/customers/reorder-team/*/*",
  CONTACT_ME = "/app/customers/contact-me",
  CREATE_ONE_LESSON = "/app/lessons/0",
  CREATE_PL = "/app/sellers/pl",
  CREATE_PRO = "/app/sellers/pro",
  CREATE_STUDENT = "/app/customers/student",
  FEEDBACK = "/app/feedback/*",
  MESSAGES = "/app/messages",
  PROFILE = "/app/profiles/*",
  RATE_LESSON = "/lessons/rate/*",
  SEND_FIRST_REPORT = "/app/reports/first",
  SEND_REGULAR_REPORT = "/app/reports/",
  SEND_TEAM_INFO = "/app/teams/*/info",
  SIGN_TEAM_CONTRACT = "/app/teams/*/sign/*",
  USER = "/users/*",
}
