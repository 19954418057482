import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
import { userModule } from "@/store/modules/user";

const ENDPOINT = "/app/news";

export async function getAllNewsMessagesForUser(): Promise<Learnlink.NewsMessage.default[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.NewsMessage.default[]>(
    `${ENDPOINT}/getAllNewsMessages`
  );
  return res.data;
}

export async function getUnreadNewsMessagesCount(): Promise<number> {
  const res = await learnlinkAPI.axios.get<number>(
    `${ENDPOINT}/getUnreadNewsMessageCount/${userModule.state.userId}`
  );
  return res.data;
}
