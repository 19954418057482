























import { filterArrayUniques } from "@/helpers/ArrayUtils";
import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";
import { computed, defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "WeekPickerForSingleWeekCourses",
  props: {
    teamCourseSessions: {
      type: Array as PropType<Learnlink.App.Onboarding.TeamCourseSession[]>,
      required: true,
    },
    value: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const weeks = computed(() =>
      props.teamCourseSessions
        .map(getWeek)
        .filter(filterArrayUniques)
    );

    function getWeek(teamCourseSession: Learnlink.App.Onboarding.TeamCourseSession): string {
      return Moment.unix(teamCourseSession.session.startTime).week();
    }

    return {
      _value,
      weeks,
    };
  }
});
