import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/platform-settings";

export async function getOnboardingSettings(): Promise<Learnlink.PlatformSettings.Onboarding> {
  const res = await learnlinkAPI.axios.get<Learnlink.PlatformSettings.Onboarding>(
    `${ENDPOINT}/onboarding`
  );
  return res.data;
}

export async function getTeamSettings(): Promise<Learnlink.PlatformSettings.Teams> {
  const res = await learnlinkAPI.axios.get<Learnlink.PlatformSettings.Teams>(
    `${ENDPOINT}/teams`
  );
  return res.data;
}

export async function getPracticalInfo(): Promise<Learnlink.PlatformSettings.PracticalInfo> {
  const projectsRes = await learnlinkAPI.axios.get<Learnlink.PlatformSettings.PracticalInfo>(
    `${ENDPOINT}/practical-info`
  );
  return projectsRes.data;
}
