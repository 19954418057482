import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

export async function deleteProfilePhoto(userId: string): Promise<void> {
  await learnlinkAPI.axios.delete<void>(
    `/app/profiles/photo/${userId}`,
  );
}

export async function getAccount(userId: string): Promise<Learnlink.Account.default> {
  const accountsRes = await learnlinkAPI.axios.get<Learnlink.Account.default>(
    `/accounts/${userId}`,
  );
  return accountsRes.data;
}

export async function getProfile(userId: string): Promise<Learnlink.Profile.default> {
  const profilesRes = await learnlinkAPI.axios.get<Learnlink.Profile.default>(
    `/app/profiles/${userId}`,
  );
  return profilesRes.data;
}

export async function getUser(userId: string): Promise<Learnlink.User.default> {
  const usersRes = await learnlinkAPI.axios.get<Learnlink.User.default>(
    `/users/${userId}`,
  );
  return usersRes.data;
}

export async function updateAccount(account: Learnlink.Account.Update): Promise<Learnlink.Account.default> {
  const accountsRes = await learnlinkAPI.axios.put<Learnlink.Account.default>(
    `/accounts/${account.uid}`,
    account,
  );
  return accountsRes.data;
}

export async function updateProfile(profile: Learnlink.Profile.Update): Promise<Learnlink.Profile.default> {
  const profilesRes = await learnlinkAPI.axios.put<Learnlink.Profile.default>(
    `/app/profiles/${profile.uid}`,
    profile,
  );
  return profilesRes.data;
}

export async function updateEmail(uid: string, email: string): Promise<void> {
  await learnlinkAPI.axios.put<void>(
    `/users/updateEmail/${uid}`,
    { email }
  );
}

export async function uploadProfilePhoto(userID: string, formData: FormData): Promise<string> {
  const urlRes = await learnlinkAPI.axios.post<string>(
    `/app/profiles/uploadProfilePhoto/${userID}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  return urlRes.data;
}

export async function updateUser(user: Learnlink.User.Update): Promise<Learnlink.User.default> {
  const usersRes = await learnlinkAPI.axios.put<Learnlink.User.default>(
    `/users/${user.uid}`,
    user,
  );
  return usersRes.data;
}
