














































import { Weekday } from "@/helpers/timeUtils";
import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "DayPickerForMultiWeekCourses",
  props: {
    teamCourseSessions: {
      type: Array as PropType<Learnlink.App.Onboarding.TeamCourseSession[]>,
      required: true,
    },
    value: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    function getUniqueTimeRanges(date: Weekday): Learnlink.App.Onboarding.TeamCourseSession[] {
      return props.teamCourseSessions.filter((teamCourseSession) => {
        const weekday = Moment.unix(teamCourseSession.session.startTime).format("dddd");
        return weekday === date;
      });
    }

    return {
      _value,
      getUniqueTimeRanges,
    };
  },
});
