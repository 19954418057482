import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/courses";

export async function getCourse(courseID: string): Promise<Learnlink.Course.default & {
  price: number;
}> {
  const res = await learnlinkAPI.axios.get(
    `/app/public/course/${courseID}`
  );
  return res.data;
}

export async function listCourseSessions(courseID: string): Promise<Learnlink.CourseSession.default[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.CourseSession.default[]>(
    `${ENDPOINT}/${courseID}/sessions`);
  return res.data;
}

export async function getMyCoursesForCourseContent(uid: string): Promise<Learnlink.Course.CourseContentParams[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.Course.CourseContentParams[]>(
    `${ENDPOINT}/courseContentParamsForMyCourses/${uid}`);
  return res.data;
}

export async function getBasicCoursesForCourseContent(): Promise<Learnlink.Course.CourseContentParams[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.Course.CourseContentParams[]>(
    `${ENDPOINT}/courseContentParamsForBasicCourses`);
  return res.data;
}
