import { Analytics } from "analytics";
import segmentPlugin from "@analytics/segment";
import environment from "@/config/environment";

const analytics = Analytics({
  app: "learnlink-gucci",
  plugins: [
    segmentPlugin({
      writeKey: environment.segment.apiKey,
      syncAnonymousId: true,
    }),
  ],
});

export function getAnonymousId(): string {
  return analytics.user().anonymousId;
}

export function identifyUser(userId: string): void {
  analytics.identify(userId);
}

export default analytics;
