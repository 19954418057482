

















































































import { checkIfUserExists } from "@/api/authService";
import rules from "@/helpers/formRules";
import { sanitizePhone } from "@/helpers/stringUtils";
import analytics from "@/services/analytics";
import { defineComponent, inject, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import Learnlink from "@learnlink/interfaces";
import TermsOfUse from "./terms-of-use/TermsOfUse.vue";

export default defineComponent({
  name: "StepperForm",
  components: { TermsOfUse },
  props: {
    fields: {
      type: Array as PropType<Learnlink.App.Onboarding.OnboardingField[]>,
      required: true,
    },
    previousPage: {
      type: String as PropType<string>,
      default: "",
    },
    value: {
      type: Object as PropType<Learnlink.Customer.Create>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const { navigate } = useRouter();
    const notify = useNotifier();
    const textfield = ref(null);
    const submit = inject<() => Promise<void>>("submit");

    const vm = reactive({
      loading: false,
      consent: false,
      step: 1,
      valid: false,
    });

    onMounted(() => {
      setTimeout(() => focusField(), 200);
      analytics.track(`onboardingStep${vm.step}`);
    });

    function buttonRules(rule: Learnlink.App.Onboarding.Rule, value: string): boolean {
      const check = rule(value);
      if (check !== true) return false;
      return check;
    }

    function focusField(): void {
      const ignoreFirstElemIndex = vm.step - 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const fieldRef = textfield.value[ignoreFirstElemIndex];
      fieldRef.focus();
    }

    async function next(rule: Learnlink.App.Onboarding.Rule, value: string): Promise<void> {
      const check = rule(value);
      if (check !== true) {
        notify({
          title: "Feil! 🧐",
          message: "Sørg for at feltet er riktig fylt ut!",
          type: "error",
        });
      }
      else if (vm.step === 1) await verifyEmail(value);
      else if (vm.step === 2) verifyFullName(value);
      else if (vm.step === 3) verifyPhone(value);
      else {
        vm.step++;
        setTimeout(() => focusField(), 400);
      }
      analytics.track(`onboardingStep${vm.step}`);
    }

    async function verifyEmail(value: string): Promise<void> {
      vm.loading = true;

      const email = value.trim();
      const isEmail = rules.email(email);
      if (isEmail !== true) {
        notify({
          title: "E-posten er ugyldig!",
          message: "Prøv igjen.",
          type: "error",
        });
      }
      else {
        const userAlreadyExists = await checkIfUserExists(_value.value.email);
        if (userAlreadyExists) {
          emit("userExists");
        }
        else {
          vm.step++;
          setTimeout(() => focusField(), 400);
        }
      }

      vm.loading = false;
    }

    function verifyFullName(value: string): void {
      const isFullName = rules.fullName(value);
      if (isFullName !== true) {
        notify({
          title: "Fullt navn!",
          message: "Vi trenger både fornavn og etternavn.",
          type: "error",
        });
      }
      else {
        vm.step++;
        setTimeout(() => focusField(), 400);
      }
    }

    function verifyPhone(value: string): void {
      const isPhone = rules.phone(value);
      if (isPhone !== true) {
        notify({
          title: "Telefonnummeret er ugyldig!",
          message: "Prøv igjen.",
          type: "error",
        });
      }
      else {
        done();
      }
    }

    function previous(): void {
      vm.step > 1
        ? vm.step--
        : navigate(props.previousPage);
    }

    async function done() {
      vm.loading = true;
      if (!vm.valid || !vm.consent) {
        notify({
          title: "Noe mangler!",
          message: "Husk å fylle ut alle felt, og godkjenne bruksvilkårene.",
          type: "error",
        });
      }
      else {
        submit && await submit();
      }
      vm.loading = false;
    }

    function sanitize(prop: string): void {
      if (prop === "phone") {
        _value.value.phone = sanitizePhone(_value.value.phone);
      }
    }

    return {
      _value,
      buttonRules,
      next,
      previous,
      sanitize,
      textfield,
      vm,
    };
  },
});
