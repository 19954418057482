












































































































































import { sendRegisteredSellerConfirmation } from "@/api/sellerService";
import JobDescriptionsFooter from "@/components/seller/JobDescriptionsFooter.vue";
import handleError from "@/helpers/errors";
import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { listFiles } from "@/api/fileService";
import { userModule } from "@/store/modules/user";
import EditProfile from "@/components/profile/EditProfile.vue";
import Uploader from "@/components/settings/upload/Uploader.vue";

export default defineComponent({
  name: "SellerOnboarding",
  components: {
    JobDescriptionsFooter,
    EditProfile,
    Uploader,
  },
  setup() {
    const vm = reactive({
      certificatesApproved: false,
      loading: false,
      step: 1,
    });

    const firstName = computed(() => userModule.state.profile.firstName);
    const progress = computed(() => (100 / 4) * vm.step);

    onMounted(async () => {
      vm.loading = true;
      const certificates = await listFiles("certificates", userModule.state.userId);

      if (userModule.state.profile.aboutText && userModule.state.profile.photoURL) {
        vm.step = 3;
      }
      if (certificates.length) {
        vm.step = 4;
      }
      vm.loading = false;
    });

    function next(): void {
      vm.step++;
      if (vm.step === 4) sendConfirmationEmail();
    }

    function prev(): void {
      vm.step--;
    }

    async function sendConfirmationEmail() {
      try {
        await sendRegisteredSellerConfirmation(userModule.state.userId);
      }
      catch (e) {
        handleError(e);
      }
    }

    return {
      firstName,
      next,
      prev,
      progress,
      vm,
    };
  },
});
