export function capitalize(str: string): string {
  return str[0].toUpperCase() + str.substr(1);
}

export function createLinks(content: string): string {
  const URLmatch = /(?:(?:https?|http?):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm;
  const withLinks = content.replace(URLmatch, match => `<a target="_blank" href="${match}">${match}</a>`);
  return withLinks;
}

export async function copyToClipboard(text: string): Promise<void> {
  await navigator.clipboard.writeText(text);
}

export function isPlaceholderEmail(email: string): boolean {
  const emailParts = email.split("@");
  return (emailParts[0].length === 8 && emailParts[1].includes("learnlink.no"));
}

export function sanitizePhone(phone: string): string {
  return phone.replace(/\s/g, "").replace("+47", "");
}

export function sanitizeEmail(email: string): string {
  return email
    .replace(/\s/g, "")
    .replace(/æ/gi, "ae")
    .replace(/ø/gi, "o")
    .replace(/å/gi, "a");
}
