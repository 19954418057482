import { onMounted, ref, Ref } from "@vue/composition-api";

const useAsyncData = <T>(fetchOnMount: () => Promise<T>): {
    data: Ref<T | undefined>;
    hasError: Ref<Error | undefined>;
    isLoading: Ref<boolean>;
    refresh: () => Promise<void>;
  } => {
  const isLoading = ref(true);
  const data = ref<T>();
  const hasError = ref<Error>();
  const refresh = async() => await fetch();

  async function fetch() {
    try {
      data.value = await fetchOnMount();
      isLoading.value = false;
    }
    catch (e) {
      if (e instanceof Error) {
        hasError.value = e;
        isLoading.value = false;
      }
    }
  }

  onMounted(async() => {
    await fetch();
  });

  return {
    data,
    hasError,
    isLoading,
    refresh,
  };
};

export default useAsyncData;
