





















import { auth } from "@/services/firebase";
import { userModule } from "@/store/modules/user";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AppBarButtons",
  setup() {
    return {
      async signOut() {
        auth.signOut();
        userModule.actions.signOut();
      },
    };
  },
});
