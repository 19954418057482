import { Notification } from "@/providers/notifier";
import * as Sentry from "@sentry/vue";

export interface LearnlinkError extends Error {
  code?: number;
}

export default function handleError(error: unknown): void {
  const sanitizedError = sanitizeError(error);
  // eslint-disable-next-line no-console
  console.error(sanitizedError);
  Sentry.captureException(sanitizedError);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function sanitizeError(error: any): LearnlinkError {
  return error.response?.data?.error || error;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function getAuthErrorNotification(authError: any): Notification {
  if (authError.code) {
    if (authError.code === "auth/wrong-password") {
      return {
        title: "Feil passord",
        message: "",
        type: "error",
      };
    }
    else if (authError.code === "auth/user-not-found") {
      return {
        title: "Fant ikke brukeren",
        message: "",
        type: "error",
      };
    }
    else if (authError.code === "auth/invalid-email") {
      return {
        title: "Ugyldig e-postadresse",
        message: "",
        type: "error",
      };
    }
    else if (authError.code === "auth/network-request-failed") {
      return {
        title: "Fant ikke nettet",
        message: "Det ser ut til at internett-tilkoblingen din er borte. Det kan hjelpe å starte nettleseren på nytt.",
        type: "error",
      };
    }
    else if (authError.code === "auth/account-exists-with-different-credential") {
      return {
        title: "Feil innloggingsmetode",
        message: "Du har en annen innloggingsmetode tilknyttet kontoen din 🙂",
        type: "error",
      };
    }
    else if (authError.code === "auth/too-many-requests") {
      return {
        title: "For mange innloggingsforsøk på kort tid",
        message: "Brukeren din er midlertidig låst fordi du har forsøkt å logge inn for mange ganger på kort tid. Du kan åpne den ved å nullstille passordet ditt, eller prøve igjen om fem minutter.",
        type: "error",
      };
    }
    else {
      handleError(authError);
      return {
        title: "Ukjent feil",
        message: "Kontakt support hvis problemet vedvarer.",
        type: "error",
      };
    }
  }
  else {
    handleError(authError);
    return {
      title: "Ukjent feil",
      message: "Kontakt support hvis problemet vedvarer.",
      type: "error",
    };
  }
}
