import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/membership";

export async function listCharges(userId: string): Promise<Learnlink.Stripe.Charge[]> {
  const receiptsRes = await learnlinkAPI.axios.get<Learnlink.Stripe.Charge[]>(
    `${ENDPOINT}/charges/${userId}`,
  );
  return receiptsRes.data;
}

export async function listInvoices(userId: string): Promise<Learnlink.Membership.Receipt[]> {
  const receiptsRes = await learnlinkAPI.axios.get<Learnlink.Membership.Receipt[]>(
    `${ENDPOINT}/receipts/${userId}`,
  );
  return receiptsRes.data;
}

export async function getNumberOfUnpaidInvoices(userId: string): Promise<number> {
  const res = await learnlinkAPI.axios.get<number>(
    `${ENDPOINT}/invoices/unpaidCount/${userId}`,
  );
  return res.data;
}

export async function createMembership(customerUID: string, plan: string): Promise<Learnlink.Membership.default> {
  const membershipRes = await learnlinkAPI.axios.post<Learnlink.Membership.default>(
    `${ENDPOINT}/${customerUID}/${plan}`,
  );
  return membershipRes.data;
}
